import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions } from "../../store";



export const AuthCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    async function loginFromCode() {

      //Let's get the code
      const code = new URLSearchParams(window.location.search).get('code');
      if(!code) {
        //Redirect to home
        console.log("No code found")
        navigate("/");
      }

      //Let's send it to the back to try to get a token
      try {
        console.log("Sending code to get token")
        dispatch(actions.tokens.get.process({ code: code }))
      } catch (error) {
        console.log(error)
        console.log("Login failed")
      }


    }

    loginFromCode()
  }, [dispatch, navigate])
  
  return (
    <>
      <div>Auth callback</div>
    </>
  )
}