import { selectors as indexConfig } from './indexConfig'
import { selectors as ticketCategories } from './ticketCategories'
import { selectors as tickets } from './tickets'
import { selectors as client } from './client'
import { selectors as loginUrl } from './loginUrl'
import { selectors as tokens } from './tokens'
import { selectors as clients } from './clients'

export const selectors = {
  indexConfig,
  ticketCategories,
  tickets,
  client,
  clients,
  loginUrl,
  tokens
}
