import { ArrowBackIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, EditIcon } from "@chakra-ui/icons"
import { Box, Button, Grid, HStack, Input, Spacer, Stack, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { IndexLogo } from "../../components/logo"
import { actions } from "../../store"
import { selectors } from "../../store/selectors"

export const ClientList = () => {

  const dispatch = useDispatch()
  const indexConfig = useSelector(selectors.indexConfig.getSelector)
  const clients = useSelector(selectors.clients.getSelector)
  const navigate = useNavigate()
  const [getSearch, setSearch] = useState('');
  const [getPage, setPage] = useState(1);
  const [getLastPage, setLastPage] = useState(1);

  useEffect(() => {
    dispatch(actions.clients.get.process({
      page: getPage,
      search: getSearch
    }))
  }, [dispatch, getSearch, getPage])
  
  useEffect(() => {
    if(clients?.meta?.totalPages) {
      setLastPage(Number(clients?.meta?.totalPages))
    }
  }, [clients])

  function handleSearchChange(e) {
    setSearch(e.target.value);
  }

  function getPreviousPage() {
    setPage(getPage - 1)
  }

  function getNextPage() {
    setPage(getPage + 1)
  }

  return (
    <>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3} bg='white'>
          <VStack>
            <Input placeholder='Recherche' size='md' width={500} marginTop={50} marginBottom={50} value={getSearch} onChange={(e) => handleSearchChange(e)}/>
            <TableContainer width={'70%'} >
              <Table>
                <Thead>
                  <Tr>
                    <Th>Prénom</Th>
                    <Th>Nom</Th>
                    <Th isNumeric>Téléphone</Th>
                    <Th>Voir</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {clients && clients.data && clients.data.map((client) => {
                    return (
                      <Tr>
                        <Td>{client.firstName}</Td>
                        <Td>{client.lastName}</Td>
                        <Td isNumeric>{client.phoneNumber}</Td>
                        <Td><Button onClick={()=>{navigate(`/clients/${client.uuid}`)}}><EditIcon /></Button></Td>
                      </Tr>
                    )
                  })}
                  
                </Tbody>
              </Table>
            </TableContainer>
            <div>
              <Button colorScheme='primary' padding={0} margin={0} borderRadius={0} borderLeftRadius={10} disabled={getPage === 1} onClick={getPreviousPage}><ChevronLeftIcon/></Button>
              <Button colorScheme='primary' padding={0} borderRadius={0}>{getPage}/{getLastPage}</Button>
              <Button colorScheme='primary' padding={0} borderRadius={0} borderRightRadius={10} disabled={getPage === getLastPage} onClick={getNextPage}><ChevronRightIcon/></Button>
            </div>
          </VStack>
        </Grid>
      </Box>
    </>
  )
}