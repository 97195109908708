import home from './home/index'
import clients from './clients/index'
import auth from './auth/index'
import calendar from './calendar/index'

export const routes = [
  ...home,
  ...clients,
  ...auth,
  ...calendar
]
