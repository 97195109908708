import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { paths } from './path'
import { selectors } from '../store/selectors'

const PrivateRoute = () => {
  const tokens = localStorage.getItem('tokens')

  if (tokens) {
    return (
      <Outlet />
    )
  }
  // Get actual url
  localStorage.setItem('lastPageBeforeLogin', window.location.pathname)
  return <Navigate to={paths.AUTH_LOGIN} />
}

export default PrivateRoute
