import { combineReducers } from '@reduxjs/toolkit'
import { reducer as indexConfigReducer } from './indexConfig'
import { reducer as ticketCategoriesReducer } from './ticketCategories'
import { reducer as ticketsReducer } from './tickets'
import { reducer as clientReducer } from './client'
import { reducer as loginUrlReducer } from './loginUrl'
import { reducer as tokens } from './tokens'
import { reducer as clientsReducer } from './clients'

import { errorReducer } from './error/reducer'

const appReducer = combineReducers({
  indexConfig: indexConfigReducer,
  ticketCategories: ticketCategoriesReducer,
  tickets: ticketsReducer,
  client: clientReducer,
  clients: clientsReducer,
  loginUrl: loginUrlReducer,
  tokens: tokens,
  errors: errorReducer,
})

export const reducer = (state, action) => {
  return appReducer(state, action)
}