import { fork, all } from 'redux-saga/effects'
import { watchErrors } from './error/sagas'
import { sagas as indexConfigSagas } from './indexConfig'
import { sagas as ticketCategoriesSagas } from './ticketCategories'
import { sagas as tickets } from './tickets'
import { sagas as client } from './client'
import { sagas as loginUrl } from './loginUrl'
import { sagas as tokens } from './tokens'
import { sagas as clients } from './clients'


export function* rootSaga() {
  yield all([
    fork(indexConfigSagas()),
    fork(ticketCategoriesSagas()),
    fork(tickets()),
    fork(client()),
    fork(loginUrl()),
    fork(tokens()),
    fork(clients()),
    yield fork(watchErrors),
  ])
}
