/* eslint-disable import/no-anonymous-default-export */
import { call, put } from 'redux-saga/effects'
import api from '../../../services/api'
import { sagas } from '../../utils'
import { get as actions } from '../actions'

export default () =>
  function* getUserToken({ payload }) {
    try {
      const { code } = payload;
      yield put(actions.loading());
      const tokens = yield call(api.auth.getToken() as any, { data: { code } });
      localStorage.setItem('tokens', JSON.stringify(tokens));
      yield put(actions.success(tokens));

    } catch (err) {
      console.log(err);
      yield put(actions.error(err));
    }
  }
