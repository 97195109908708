import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "../../store/selectors";
import { actions } from "../../store"
import { useNavigate } from "react-router-dom";
import session from "redux-persist/lib/storage/session";

export const Login = () => {

  const dispatch = useDispatch();
  const loginUrl = useSelector(selectors.loginUrl.getSelector);
  const navigate = useNavigate();
  const [externalPopup, setExternalPopup] = useState(null);
  
  useEffect(() => {
    console.log("Getting login url")
    dispatch(actions.loginUrl.get.process())
  }, [dispatch])

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      const currentUrl = externalPopup.location.href;
      if (!currentUrl) {
        return;
      }

      console.log(currentUrl)

      if(currentUrl.startsWith(process.env.REACT_APP_FRONT_URL) && localStorage.getItem('tokens')) {
        console.log("Closing popup")
        timer && clearInterval(timer);
        externalPopup.close();
        // Refresh the page
        setTimeout(() => {
          console.log("Redirecting to home")
          navigate("/")
        }, 1000)
        // if(localStorage.getItem('lastPageBeforeLogin')) {
        //   console.log("Redirecting to last page before login")
        //   console.log(localStorage.getItem('lastPageBeforeLogin'));
        //   setTimeout(() => {
        //     console.log("Redirecting now ! ")
        //     navigate(localStorage.getItem('lastPageBeforeLogin') as string)
        //   }, 1000)
        // } else {
        //   console.log('Redirecting to home')
        //   navigate("/")
        // }
      }

    }, 500)
  }, [externalPopup]
  )

  useEffect(() => {
    //console.log(externalPopup?.location?.href)
    console.log(loginUrl)
    if(loginUrl && !externalPopup) {
      console.log('Opening popup for login')
      const popup = window.open(loginUrl, '_blank', 'width=600,height=600');
      setExternalPopup(popup);
      //window.location.href = loginUrl;
    }
  }, [loginUrl, externalPopup])
  
  return (
    <>
      <div>Login</div>
    </>
  )
}