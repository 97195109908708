import { Badge, Box, Button, Grid, Image, Tab, TabList, TabPanel, TabPanels, Tabs, useToast, VStack } from "@chakra-ui/react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectors } from "../../store/selectors"
import { ClientPersonnalInfo } from "./ClientPersonnalInfo"
import { ClientTickets } from "./ClientTickets"
import { actions } from "../../store"
import { CopyIcon } from "@chakra-ui/icons"

export const Client = () => {

  const dispatch = useDispatch()
  const client = useSelector(selectors.client.getSelector)
  const { pathname } = window.location
  const uuid = pathname.split('/')[2]
  const location = pathname.split('/').length > 3 ? pathname.split('/')[3] : 'Localisation inconnue'
  const toast = useToast({
    variant: "toast_success",
  })

  useEffect(() => {
    console.log('ici')
    dispatch(actions.client.get.process({
      uuid
    }))
  }, [dispatch, uuid])

  return (
    <>
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3} bg='white'>
        <VStack>
          <VStack>
            <Box display="flex" justifyContent="center">
              <Image
                borderRadius="full"
                boxSize={100}
                bg="white"
                borderColor="primary.400"
                src={client?.profilePicture ? client.profilePicture.thumbnailSizeUrl : ''}
                alt={`Pas de photo`}
              />

            </Box>
            <h2 style={
              {
                fontWeight: '100',
              }
            }>
              {client?.firstName ? client.firstName : ''} {client?.lastName ? client.lastName : ''}
            </h2>
            <p style={
              {
                fontWeight: '100',
                fontSize: '12px',
                marginTop: '0',
                marginBottom: '0',
              }
            }>
              UUID : {client?.uuid ? client.uuid : ''}
              <Button
                  ml={2}
                  mb={1}
                  size="xs"
                  onClick={() => {
                    navigator.clipboard.writeText(`${client?.uuid}`)
                    toast({
                      title: 'UUID copié !',
                      status: 'success',
                      duration: 1000,
                      isClosable: true,
                    })
                  }}
                >
                <CopyIcon color='primary'/>
              </Button>
              
            </p>
            <p style={
              {
                fontWeight: '100',
                fontSize: '14px',
                marginTop: '0',
                marginBottom: '0',
              }
            }>
                { location ? '📍 ' + location : ''}
            </p>

            <Badge colorScheme='orange' style={{marginTop: '10px'}}>{client?.role ? client.role : ''}</Badge>

          </VStack>
          <Tabs width='100%'>
            <TabList>
              <Tab>Infos personnelles</Tab>
              <Tab>Demandes</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ClientPersonnalInfo/>
              </TabPanel>
              <TabPanel>
                <ClientTickets/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Grid>
    </Box>
    </>
  )
}