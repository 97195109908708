import { Box, Button, Grid, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, VStack } from "@chakra-ui/react"
import { IndexLogo } from "../../components/logo"
import { ImportCSV } from "../Csv/ImportCSV"
import { Navigate, useNavigate } from "react-router-dom"

export const WaitingPage = () => {
  const uploadCSVModalDisclosure = useDisclosure()
  const navigate = useNavigate();

  return (
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={80} >
        <VStack>
          <IndexLogo width={'100%'} height={'100px'}/>
          <Button colorScheme='blue' width={500} onClick={uploadCSVModalDisclosure.onOpen}>Enregistrer des rendez-vous (csv)</Button>
          <Modal isOpen={uploadCSVModalDisclosure.isOpen} onClose={uploadCSVModalDisclosure.onClose} size='full'>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Ajouter des rendez-vous</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ImportCSV/>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='primary' mr={3} onClick={uploadCSVModalDisclosure.onClose}>Fermer</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/* <Button colorScheme='blue' width={500} onClick={()=>{navigate("/clients/")}}>Répertoire utilisateur</Button> */}
        </VStack>
      </Grid>
    </Box>
  )
}