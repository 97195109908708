import { Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { WaitingPage } from "../views/WaitingPage";
import { routes } from "./routes";
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const Main = () => {
  return (
    <Sentry.ErrorBoundary fallback={
      <WaitingPage />
    } >
      <SentryRoutes>
        {routes}
      </SentryRoutes>
    </Sentry.ErrorBoundary>
  )
}