import { request } from '../../http'

const req = async ({ data, headers }) => 
  request({
    route: `/call-center/clients/?limit=5&page=${data.page ? data.page : 1}&search=${data.search ? data.search : ''}`,
    method: 'GET',
    headers,
    dataType: 'json',
    data: {},
    // params: {
    //   search: data.search ? data.search : '',
    //   page: data.page ? data.page : 1,
    // }
  })

export default req

